const originalError = window.console.error;

window.console.error = function (message: any, ...args: any[]) {
  if (typeof message === 'string' &&
    (message?.includes('findDOMNode is deprecated')
    || message?.includes('changing an uncontrolled input to be controlled')
    || message?.includes('defaultProps will be removed'))
  ) {
    console.log(`\x1b[33mSuppressed (suppressErrors.ts): ${message}\x1b[0m`);
    return;
  }
  originalError.apply(console, [message, ...args]);
};

export {};